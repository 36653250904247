import * as React from 'react'
import { Link } from 'gatsby'
import Layout from "../components/layout"
import Seo from "../components/seo"

const ServicesPage = () => {
  return (
    <Layout pageTitle="Services">
      <h1>Our Services</h1>
      <p>At Toast Technology Pty Ltd, we offer a range of consulting services to help businesses in Australia leverage cutting-edge technologies like Robotics, Computer Vision, Artificial Intelligence, and autonomous systems. Our services are tailored to meet the specific needs of each client, and we work closely with them to develop solutions that drive innovation and transformation.</p>

      <h2>Our Services include:</h2>
      <ul>
        <li>Consulting on Robotics and Autonomous Systems</li>
        <li>Designing and Implementing Computer Vision systems</li>
        <li>Artificial Intelligence Consulting and Implementation</li>
        <li>Deep Learning Consulting and Implementation</li>
        <li>Sensor Fusion Consulting and Implementation</li>
      </ul>

      <p>Our founder, Nick Hortovanyi, graduated from the world's first Self-Driving Car Engineer Nanodegree program from Udacity, based in Silicon Valley. He then went on to complete additional programs in Robotics Software Engineering, Deep Reinforcement Learning, and Sensor Fusion. With his deep understanding of these cutting-edge technologies, Nick saw an opportunity to create a consulting firm that could help businesses in Australia leverage these technologies to improve their operations and drive innovation. </p>

      <p>We recognize that the market for Robotics, Computer Vision, Artificial Intelligence, and autonomous systems is still in its early stages in Australia. As such, our consulting services are tailored to each client, and we work closely with them to understand their unique requirements and goals. We have the capability to source sensors, technology, and expertise from around the globe to satisfy niche requirements that a client may have.</p>

      <h2>Team Building and Education</h2>
      <p>At Toast Technology, we believe that building the right team is critical to the success of any project involving emerging technologies. Our consulting services include support for team building, recruitment, and training to ensure that our clients have the right people in place to achieve their goals.</p>

      <p>We can provide educational programs that cater to a wide range of skill levels, from programmers to company directors. Our programs cover everything from the fundamentals of Robotics, Computer Vision, and Artificial Intelligence, to advanced topics like Deep Learning and Sensor Fusion. We work closely with our clients to customize our training programs to their specific needs and goals, and can provide both on-site and remote training options.</p>

      <p>At Toast Technology, we are committed to staying at the forefront of emerging technologies and are constantly seeking new opportunities to help businesses leverage the power of autonomous systems and AI. Contact us to learn more about how we can help your business drive innovation and transformation.</p>
    </Layout>
  )
}

export const Head = () => <Seo description="Toast Technology Pty Ltd offers a range of consulting services to help businesses build teams and implement AI and autonomous systems" title="Services" />

export default ServicesPage